




















































































































































































.yxrwnc {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;

  .y1 {
    p {
      line-height: 25px;

      .yxrwnc_1 {
        width: 200px;
        text-align: right;
      }

      .yxrwnc_2 {
        color: #409EFF;
      }

      i:hover {
        color: red;
        cursor: pointer;
      }
    }
  }

  .y2 {
    padding-top: 60px;
  }

}
