.yxrwnc {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
}
.yxrwnc .y1 p {
  line-height: 25px;
}
.yxrwnc .y1 p .yxrwnc_1 {
  width: 200px;
  text-align: right;
}
.yxrwnc .y1 p .yxrwnc_2 {
  color: #409EFF;
}
.yxrwnc .y1 p i:hover {
  color: red;
  cursor: pointer;
}
.yxrwnc .y2 {
  padding-top: 60px;
}
